/* 
  SAMPLE CSS FOR DARK MODE (put this in your .css, e.g. multiRunHistoryModal.css, or a global .css)
  Adjust colors to match your desired theme.
*/


.dark-modal .modal-content {
  background-color: #2c2c2c;
  color: #fff;
  border: none;
}
.dark-modal-header,
.dark-modal-footer {
  background-color: #2c2c2c;
  border: none;
}
.dark-modal-body {
  background-color: #2c2c2c;
  color: #fff;
}
.dark-table {
  background-color: transparent;
}
.dark-table thead th {
  background-color: #3c3c3c;
  color: #fff;
}
.dark-select, .dark-input {
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
}

