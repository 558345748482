.header-image-container {
    width: 25%; /* Adjust the width as needed */
    height: 25%; /* Adjust the height as needed */
    overflow: hidden; /* Hide the overflowing parts of the image */
}

.header-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

/* Add new styles for the logo text to control its size */
.logo-text img {
    max-height: 30px; /* Adjust this value to control the height */
    width: auto; /* Maintain aspect ratio */
}

/* Add styles for the logo icon to control its size */
.logo-icon img {
    max-height: 40px; /* Match the text height */
    width: auto; /* Maintain aspect ratio */
    vertical-align: middle;
}
  