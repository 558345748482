/* BlueRuleMenu.css */
.flex-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
}

.left-panel {
    flex: 1;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    background-color: #f5f5f5;
    padding: 20px;
}

.right-panel {
    flex: 2;
    overflow-y: auto;
    background-color: #424953;
    padding: 20px;
}

.loading-container {
    text-align: center;
    font-size: 24px;
    color: white;
}

/* Modern styling for client filter select component */
.custom-filter-container {
    margin-bottom: 1.5rem;
    overflow: visible !important;
}

.client-filter-wrapper {
    position: relative;
}

.client-select {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.client-select__control {
    transition: all 0.2s ease;
}

.client-select__control:hover {
    border-color: #5cb85c !important;
}

.client-select__control--is-focused {
    border-color: #5cb85c !important;
    box-shadow: 0 0 0 1px #5cb85c !important;
}

.client-select__menu {
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 4px;
}

.client-select__option {
    transition: background-color 0.2s ease;
}

.client-select__multi-value {
    transition: all 0.2s ease;
}

.client-select__multi-value:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.client-select__multi-value__remove:hover {
    background-color: #dc3545 !important;
    color: white !important;
}

/* Ensure no unwanted scrollbars appear */
body {
    overflow-x: hidden !important;
}

body.ReactModal__Body--open,
body.modal-open {
    overflow-y: hidden !important;
}

/* Add explicit scrollbar prevention */
.custom-filter-container {
    margin-bottom: 1.5rem;
    overflow: visible !important;
}

/* Force scrollbar hiding on specific elements */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #2c3034;
}

::-webkit-scrollbar-thumb {
    background: #5a6268;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #6c757d;
}

/* Hide horizontal scrollbars completely */
*::-webkit-scrollbar-horizontal {
    display: none;
}

/* Prevent horizontal scrolling */
html, body, #root, .app-container {
    max-width: 100%;
    overflow-x: hidden !important;
}

/* Remove the old fix sections that are replaced by the modern styling above */

/* Disable all horizontal scrolling */
body.no-horizontal-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    margin-right: 0 !important;
    width: 100% !important;
    height: 100% !important;
    padding-right: 0 !important;
}

/* Prevent scrollbar appearance */
body.no-horizontal-scroll .client-select__menu-portal {
    overflow: visible !important;
}

/* Ensure the portal is placed correctly with high z-index */
.client-select__menu-portal {
    z-index: 9999 !important;
    position: fixed !important;
}

/* Specific style to prevent double scrollbars in BlueRulesManu */
.blue-rules-container {
    background-color: #2a2d33;
    min-height: 100vh;
    padding: 20px;
}

.blue-rules-container > div {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    max-height: 100% !important;
}

.blue-rules-content {
    background-color: #2a2d33;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 100% !important;
    max-height: 100% !important;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.card-title {
    margin-bottom: 0.5rem;
}

.card-subtitle {
    margin-bottom: 0.5rem;
}

.card-description {
    flex: 1;
    margin-bottom: 1rem;
}

.card-clients {
    margin-top: auto;
    padding-top: 0.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.client-badge {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    background-color: #5a6268;
    color: white;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

.no-horizontal-scroll {
    overflow-x: hidden;
}

.filter-container {
    margin-bottom: 1rem;
}

.filter-container h3 {
    margin-bottom: 0.5rem;
}

.active-filters {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
}

.active-filters .badge {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.client-select {
    width: 100%;
}

.client-select__control {
    background-color: #34383f;
    border-color: #6c757d;
}

.client-select__menu {
    background-color: #34383f;
    border: 1px solid #6c757d;
}

.client-select__option {
    background-color: #34383f;
    color: white;
}

.client-select__option--is-focused {
    background-color: #5a6268;
}

.client-select__multi-value {
    background-color: #5a6268;
    color: white;
}

.client-select__multi-value__remove {
    color: white;
}

.client-select__multi-value__remove:hover {
    background-color: #dc3545;
}

.client-select__placeholder {
    color: #adb5bd;
}

.client-select__input {
    color: white;
}
